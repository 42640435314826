/* Use the stylex priority1 layer to make the reset the least specific */
@layer priority1 {

	/* http://meyerweb.com/eric/tools/css/reset/ 
	v2.0 | 20110126
	License: none (public domain)
	*/

	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure, 
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	/* Own additions */

	a { text-decoration: none }

	button { margin: 0 }

	strong strongCss,
	input[type="text"]::-ms-clear { display: none }

	button::-moz-focus-inner { padding: 0; border: 0 }

	:focus { outline: 0 }

	* {
		WebkitTapHighlightColor: transparent;
		-webkit-tap-highlight-color: transparent;
		WebkitTouchCallout: none;
	}

	html {
		WebkitTextSizeAdjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */;
		WebkitPrintColorAdjust: exact !important; /* Keep background colors for printing */
	}

	@page { margin: 0 } /* Printing */

}

@stylex;